const firebaseConfig = {
  apiKey: "AIzaSyCBNawT4FJSlEvLbLti9JivxHUS0D_esWc",
  authDomain: "payee-production.firebaseapp.com",
  databaseURL: "https://payee-production.firebaseio.com",
  projectId: "payee-production",
  storageBucket: "payee-production.appspot.com",
  messagingSenderId: "616840065864",
  appId: "1:616840065864:web:98fe154f4bac201b9b797d",
  measurementId: "G-Z6TWZ57PNJ"
};
export default firebaseConfig;
